<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <v-card class="pb-5">
      <v-alert
        v-model="alert"
        color="#fdf0d7"
        dense
        type="info"
        class="text-center black--text caption mb-0"
        dismissible
        >{{ $t("candidade_imported_alert") }}</v-alert
      >
      <v-card-text>
        <v-row class="d-flex flex-column justify-center mt-1">
          <v-tabs v-model="tab" background-color="white" dark>
            <v-tab class="px-12" v-for="(item, index) in items" :key="item.tab">
              <v-btn
                v-if="index === 0"
                color="primary"
                dense
                @click="overlay = true"
                >{{ $t(item.tab) }}
              </v-btn>
              <v-btn
                v-else
                text
                color="primary"
                style="margin-left: -75%; background: #fdf0d7"
                dense
                @click="overlay = true"
                >{{ $t(item.tab) }}
              </v-btn>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row class="d-flex justify-center" no-gutters>
                <v-col cols="12" class="px-9">
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-row class="py-4 px-6">
                    <span style="color: #000"
                      >{{ $t("candidade_imported_label") }}
                    </span>
                    <download-excel
                      :data="json_data"
                      :fields="json_fields"
                      worksheet="My Worksheet"
                      type="csv"
                      name="exemplo.csv"
                    >
                      <a type="submit" style="margin-left: 25%">
                        {{ $t("candidade_imported_label_1") }}
                      </a>
                    </download-excel>
                  </v-row>
                </v-col>
              </v-row>
              <vue-dropzone
                v-if="resultCandidates.length <= 0"
                id="dropzone"
                :options="word1"
                ref="dropzone"
                @vdropzone-queue-complete="startImport"
                @vdropzone-drop="startLoading"
                @vdropzone-files-added="startLoading"
              ></vue-dropzone>
              <v-list v-else class="mx-10" dense>
                <v-list-item
                  v-for="(candidate, index) in resultCandidates"
                  :key="candidate.email"
                  class="pb-0 mb-0"
                  :style="{'align-items':'center'}"
                >
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-account-circle </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ candidate.name }}
                      {{ candidate.lastName }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      candidate.email
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{
                      candidate.telephone
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-icon @click="removeCandidateImported(index)">
                    <v-icon color="error">mdi-close-circle</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-tab-item>
            <v-tab-item>
              <candidate-form @cancel="$emit('close')" class="mt-2" />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
          <v-btn
            v-show="tab === 0"
            text
            class="mx-8"
            @click="$emit('close')"
            color="primary"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            v-show="isImported"
            :disabled="isLoading"
            color="primary"
            @click="save()"
            >{{ $t("save") }}</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import {
  CREATE_INPORTED_CANDIDATES_MUTATION,
  IMPORT_CANDIDATES_MUTATION,
  IMPORT_CANDIDATE_FROM_CURRICULUM_MUTATION,
} from "./../graphql/Mutation";
import { mapActions } from "vuex";
import { formatError } from "@/utils";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import CandidateForm from "./../components/Form.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "AddCandidateDialog",
  components: {
    CandidateForm,
    vueDropzone,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
  },
  mixins: [ADD_DIALOG],
  props: {
    dialog: Boolean,
  },
  data: () => ({
    alert: true,
    // dropzoneOptions: {
    //   url: "https://httpbin.org/post",
    //   thumbnailWidth: 200,
    //   addRemoveLinks: true,
    //   dictDefaultMessage:
    //     '<div class="v-card v-sheet theme--light grey lighten-3" style="height: 250px;"><div class="text-center"><p class="text-center py-10">Solte ou arraste nesta esta área para importar ou clique no botão abaixo</p><div style="margin-top:-5%"><i aria-hidden="true" class="v-icon notranslate mb-5 v-icon--right mdi mdi-cloud-upload theme--light primary--text"></i></div><button type="button" class="v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default"><span class="v-btn__content">importar ficheiro</span></button></div><p class="text-center pt-2 overline">.pdf .doc .docx .rtf</p></div>',
    // },
    items: [
      { tab: "candidade_imported_csv", content: "Tab 1 Content" },
      { tab: "candidade_imported_cad_cand", content: "Tab 2 Content" },
      // { tab: "Importar Curriculum", content: "Tab 3 Content" },
    ],
    json_data: [
      {
        name: "xxxx",
        lastName: "xxxx",
        email: "xxxx@exemple.com",
        telephone: "8000000000",
      },
    ],
    json_fields: {
      name: "name",
      lastName: "lastName",
      email: "email",
      telephone: "telephone",
    },
    overlay: true,
    resultCandidates: [],
    tab: null,
  }),
  computed: {
    isImported() {
      return this.resultCandidates.length > 0;
    },
    word1() {
      return {
        url: "https://httpbin.org/post",
        thumbnailWidth: 200,
        addRemoveLinks: true,
        dictDefaultMessage: this.$t("candidade_imported_label_drag"),
      };
    },
  },
  methods: {
    ...mapActions({
      setCandidateState: "candidate/setCandidate",
    }),
    removeCandidateImported(index) {
      this.resultCandidates.splice(index, 1);
    },
    async save() {
      try {
        this.isLoading = true;
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_INPORTED_CANDIDATES_MUTATION,
          variables: {
            candidates: this.resultCandidates,
            fromCandidate: true,
          },
        });
        data.createImportedCandidates.forEach((candidate) => {
          this.setCandidateState(candidate);
        });
        
        // eslint-disable-next-line no-undef
        Fire.$emit("addedCandidates", data.createImportedCandidates);

        this.success = this.$t("importCandidatesuccess");
        this.showSuccess = true;
        this.resultCandidates = [];
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async startImport() {
      try {
        const candidates = await this.$refs.dropzone.getAcceptedFiles();
        if (candidates.length > 0) {
          if (
            candidates[0].type == "text/csv" ||
            candidates[0].type == "application/vmd.ms-excel" ||
            candidates[0].type == "application/vnd.ms-excel"
          ) {
            const { data } = await this.$apollo.mutate({
              mutation: IMPORT_CANDIDATES_MUTATION,
              variables: {
                candidates: candidates[0],
              },
            });
            this.resultCandidates = data.importCandidates.filter((c) => {
              if (c) {
                return true;
              } else {
                return false;
              }
            });
          } else {
            const curriculums = candidates;
            const { data } = await this.$apollo.mutate({
              mutation: IMPORT_CANDIDATE_FROM_CURRICULUM_MUTATION,
              variables: {
                curriculums,
              },
            });
            this.resultCandidates = data.importCandidateFromCurriculum.filter(
              (c) => {
                if (c) {
                  return true;
                } else {
                  return false;
                }
              }
            );
            if (this.resultCandidates.length === 0) {
              this.error = this.$t("email_company");
              this.showError = true;
            }
          }

        }
      } catch (error) {
        this.error = formatError(error.message);
        if (this.error === 'Info not exist') {
          this.error = this.$t('no_emails_contact_details');
        }
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async startImportCurriculum() {
      try {
        const curriculum = await this.$refs.dropzone.getAcceptedFiles()[0];
        const { data } = await this.$apollo.mutate({
          mutation: IMPORT_CANDIDATE_FROM_CURRICULUM_MUTATION,
          variables: {
            curriculum,
          },
        });
        this.resultCandidates.push(data.importCandidateFromCurriculum);
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    startLoading() {
      this.isLoading = true;
    },
  },
};
</script>

<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.dropzone {
  /* padding-top: 50px; */
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 0;
  border: none;
}
.dropzone .dz-message {
  margin: 0;
}
</style>
