<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card class="pb-5">
      <v-card-title class="justify-center py-12 text-uppercase">{{$t(textCard.title)}}</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="client.id ? update() : save()"
        >
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                v-model="client.name"
                :label="$t('form_client_institution')+'*'"
                dense
                prepend-inner-icon="mdi-office-building"
                outlined
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="client.email"
                label="E-mail*"
                dense
                prepend-inner-icon="mdi-email"
                outlined
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="client.telephone"
                :label="$t('form_user_phone')"
                dense
                prepend-inner-icon="mdi-phone"
                outlined
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                v-model="client.address"
                :label="$t('form_client_adress')"
                dense
                prepend-inner-icon="mdi-map-marker"
                outlined
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                v-model="client.otherInfo"
                outlined
                :label="$t('form_client_info')"
                :rules="requiredRules"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-0 px-3">
            <v-btn
              :color="!show ? '' : primary"
              :text="!show"
              class="mx-2"
              @click="$emit('close')"
              style="background: #fff; color:#FAA638;"
            >{{ show ? 'Fechar' : $t('Cancel')}}</v-btn>
            <v-btn
              v-if="!show"
              :disabled="isLoading"
              color="primary"
              type="submit"
            >{{$t('save')}}</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-dialog>
</template>

<script>
import { CREATE_CLIENT_MUTATION, UPDATE_CLIENT_MUTATION } from './../graphql/Mutation'
import { formatError } from '@/utils'
import { mapActions } from 'vuex'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import { ADD_DIALOG } from '@/mixins/dialog'
export default {
  name: 'AddClientDialog',
  components: { ErrorDialog, ProgressDialog, SuccessDialog },
  mixins: [
    ADD_DIALOG
  ],
  props: {
    client: Object,
    dialog: Boolean,
    show: Boolean
  },
  data: () => ({
    valid: true,
  }),
  computed: {
     textCard () {
      return this.client.id
        ? { title: "edit_client_form", value:1}
        : { title: "cad_client_form",value:0}
    },
    emailRules (){
      return [
        v => !!v || this.$t("form_user_valid"),
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ]
    },
    requiredRules () {
      return [v => !!v || this.$t("form_user_valid")]
    }
  },
  methods: {
    ...mapActions({
      setClientState: 'client/setClient',
      updateClientState: 'client/updateClient'
    }),
    async save () {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        try {
          const { data } = await this.$apollo.mutate({
            mutation: CREATE_CLIENT_MUTATION,
            variables: {
              clientInput: this.client
            }
          })
          this.setClientState(data.createClient)
          this.success = this.$t('form_client_add_success')
          this.showSuccess = true
          this.$emit('close')
        } catch (error) {
          this.error = this.$t(formatError(error.message))
          this.showError = true
        } finally {
          this.isLoading = false
        }
      }
    },
    async update () {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        try {
          const { data } = await this.$apollo.mutate({
            mutation: UPDATE_CLIENT_MUTATION,
            variables: {
              id: this.client.id,
              clientInput: this.client
            }
          })
          this.updateClientState(data.updateClient)
          this.success = this.$t('form_client_edit_success')
          this.showSuccess = true
          this.$emit('close')
        } catch (error) { 
          this.error = formatError(error.message)
          this.showError = true
        } finally {
          this.isLoading = false
        }
      }
    }
  }
}
</script>